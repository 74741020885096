import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Button } from "./Button";
import { Alert } from "./Alert";

export const LoginForm = ({ loginError }: { loginError?: string }) => (
  <FormLoginContainer>
    {loginError && (
      <AlertContainer>
        <Alert type="error">{loginError}</Alert>
      </AlertContainer>
    )}
    <Footer>
      <Button href={loginError ? "/api/okta/logout" : "/api/okta/login"}>
        {/* <FormattedMessage id="login.submit" defaultMessage="Se connecter" /> */}
        Login
      </Button>
    </Footer>
  </FormLoginContainer>
);

export const FormLoginContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Container>
    <Row center="xs">
      <Col xs={12} sm={8} md={6} lg={4}>
        {children}
      </Col>
    </Row>
  </Container>
);

const Container = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;
`;

export const Submit = styled((props) => (
  <Button className="btn" type="submit" {...props} />
))``;

export const Footer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQuery("<=", "570px")`
    flex-direction: column-reverse;

    ${Submit} {
      width: 100%;
      margin-bottom: 20px;
    }
  `}
`;

export const AlertContainer = styled.div`
  margin-bottom: 30px;
`;
