import React from "react";
import { LoginForm } from "./LoginForm";
import { LayoutNotLogged } from "./LayoutNotLogged";

export const LayoutLogin = ({
  loginError,
  ...props
}: React.ComponentProps<typeof LayoutNotLogged> & { loginError?: string }) => {
  return (
    <LayoutNotLogged {...props}>
      <LoginForm loginError={loginError} />
    </LayoutNotLogged>
  );
};
